.sidebar_bg {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
  margin-top: 10vh;
  width: 90px;
  background-color: #22506c;
  filter: drop-shadow(7px 4px 6px rgba(0, 0, 0, 0.2));
  position: fixed;
  z-index: 0;
}

.hamburger{
    margin-top: 25px;
    
}
.hamburger img{
    width: 20px;
    cursor: pointer;
}
.sidebar_items{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
    gap: 35px;

}
.sidebar_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}
.sidebar_item > img{
    width: 20px;
}
.sidebar_item > p{
    color: #fff;
    font-size: 12px;
    text-align: center;
}
.active{
    background-image: url(../../Assets//imgs/yellow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 80px;
    margin: 0;
    padding: 0;
}
.sidebar_bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20%;
    gap: 20px;
}
.msgs > img , .events > img{
    width: 33px;
    margin-left: 10px;
}