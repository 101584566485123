.mainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 260vh;
    background-color: black;
    color: white;
    padding: 100px 0px; 
    overflow: hidden;
}


.subContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70vw;
}

.textBox{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 65vw;
        font-size: 60px;
        font-weight: 600;
        line-height: 67px;
        margin-bottom: 50px;
}

