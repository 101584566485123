.home2_bg {
  display: flex;
  flex-direction: column;
  padding: 5px 5px;
  margin-left: 25px;
  border-radius: 10px;
  background-color: #fff;

}
.home2_row1 {
  display: flex;
  justify-content: space-between;
}
.home2_row1 > p {
  font-weight: 500;
  font-size: 24px;
}
.home2_row2 {
  border-radius: 12px;
  padding: 5px 20px;
}
.home2_content {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.home2_content > img {
  width: 170px;
}
.home2_content > p {
  font-weight: 500;
  font-size: 12px;
}
.home2_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fcfbff;
  border-radius: 10px;
  margin-top: 10px;
}

.home2_boxheading {
  font-weight: 500;
  font-size: 16px;
}
.home2_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  background-color: #fff;
  border-radius: 12px;
  filter: drop-shadow(7px 4px 6px rgba(0, 0, 0, 0.2));
}
.home2_col1 {
  padding-top: 8px;
  padding-left: 15px;
}
.home2_col2 {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding-right: 150px;
}
.home2_col2 > p {
  font-size: 14px;
}
.col2_row2 {
  display: flex;
  align-items: center;
}
.col2_row2 > p {
  font-weight: 500;
  font-size: 12px;
  opacity: 0.5;
}
.home_col2_title {
  font-size: 12px;
  font-weight: 600;
}
.home_col2_location {
  font-weight: 500;
  opacity: 0.5;
}
.home2_col3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 120px;
}
.home2_col3_salary {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home2_col3_salary > p {
  font-size: 14px;
  font-weight: 600;
}
.home2_col3 > p {
  font-size: 10px;
  text-align: center;
}
.home2_col4 {
  display: flex;
  padding-left: 50px;
  padding-right: 20px;
  gap: 10px;
}
