.home_wrapper {
  display: flex;
  background: #fcfbff;
  
}

.home_col1 {
  display: flex;
  flex-direction: column;
  margin-left: 12vh;
  margin-top: 10vh;
}
.home_col2{
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 12px;
  margin-top: 10vh;

}
