aside {
  margin: auto;
  height: 100vh;
  width: 480px;
  background: rgba(47, 154, 200, 0.7);
  padding: 50px;

  .profileImage {
    height: 3.125rem;
  }
  .sideContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 40px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: -1px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    border-left: 1px solid rgba(106, 106, 106, 0.58);
    .profileImageContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .profileImg {
        height: 120px;
        padding-bottom: 0.6rem;
      }
      p {
        font-size: 1.7rem;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.7);
        font-family: "Poppins";
        margin: 0;
      }
    }
  }

  .tutorInfoList li {
    color: rgba(0, 0, 0, 0.58);
    font-weight: 500;
    line-height: 150%;
    font-size: 0.9rem;
    padding-bottom: 15px;
  }
  .tutorName {
    padding: 1.4rem;
  }
  .tutorName h2 {
    color: #6764ff;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 150%;
    margin: 0;
  }
}
