.multiselect {
    width: 120px;
  }
  
  .selectBox {
    position: relative;
  }
  
  .selectBox select {
    width: 100%;
  }
  
  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  #checkboxes {
    display: flex;
    flex-direction: column;
    border: 1px #dadada solid;
    padding: 10px;
  }
  
  #checkboxes label {
    display: flex;
    gap: 10px;
  }
  
  .selected {
    display: flex;
    gap: 20px;
  }