.home4_bg {
  /* padding: 10px; */
  width: 65vh;
}
.home4_cards {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  filter: drop-shadow(7px 4px 6px rgba(0, 0, 0, 0.2));
  padding: 10px;
  gap: 5px;
}
.home4_cards > img {
  width: 120px;
  align-self: flex-end;
}
.events_content {
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  background-color: #fff;
  border-radius: 12px;
  gap: 30px;
  filter: drop-shadow(7px 4px 6px rgba(0, 0, 0, 0.2));
}
.home4_box {
  border-radius: 0px 7px 7px 1px;
  filter: drop-shadow(7px 4px 6px rgba(0, 0, 0, 0.2));
  
}
.home4_box > p {
  font-size: 12px;
}
.home4_box .box_title {
  font-weight: 500;
}
.home4_box .box_date {
  color: red;
  font-weight: 400;
  text-align: center;
}
.home4_box .box_time {
  font-weight: 600;
}
