
.ProplanBg {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #f7f6ff;
  padding: 50px;
  gap: 30px;
  div {
    .Proplancol1 {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .Proplancol1heading {
        font-size: 28px;
        color: #4a3794;
        font-weight: 600;
      }
      .Proplancol1subheading {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.63);
        font-weight: 500;
      }

      .Proplancards {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-left: 25px;
        margin-top: 20px;

        .Proplancard {
          display: flex;
          padding: 10px;
          gap: 15px;
          align-items: center;
          background: #f7f6ff;
          box-shadow: 0px 3px 13px 1px rgba(164, 162, 255, 0.59);
          border-radius: 10px;
        }
      }
    }
  }
  .col2 {
    background: #f7f6ff;
    box-shadow: 0px 2px 15px 2px rgba(164, 162, 255, 0.59);
    border-radius: 7px;
    padding: 15px;
    width: 500px;

    .Proplancol2head {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        font-size: 28px;
        font-weight: 600;
      }
    }
    .col2Input {
      width: 20px;
      height: 20px;
    }
    .Proplancol2content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-size: 14px;

      .Proplancol2contentmain {
        font-size: 24px;
        text-align: center;
        font-weight: 600;
        color: #12b15d;

      }
      .Proplancol2contentmaincol2 {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    .Proplancol2drawnbacks {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 0 50px;
      color: rgba(0, 0, 0, 0.8);

      .Proplancol2drawnbackscol1 {
        display: flex;
        justify-content: space-between;
        padding-right: 50px;

        .Proplancol2drawnbacks1 {
          display: flex;
          gap: 10px;
          font-size: 12px;
        }
      }
    }
    .Proplancol2bottom {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 15px;
      p {
        text-align: center;
        font-size: 11.5px;
        color: rgba(0, 0, 0, 0.78);
        font-weight: 600;
      }
      button {
        all : unset;
        background: #6764ff;
        border-radius: 34px;
        padding : 10px 20px;
        text-align: center;
        color: #f7f6ff;
        margin-top: 25px;
    }
  }
}
}