.home3_bg {
  display: flex;
  flex-direction: column;
  width: 60vh;
  padding: 15px;
  background-color: #fff;
  border-radius: 12px;
  filter: drop-shadow(7px 4px 6px rgba(0, 0, 0, 0.2));
  gap: 12px ;
}
.home3_bg > img{
    width: 250px;
    align-self: center;
}
.content_col1{
    display: flex;
    flex-direction: column;
    gap: 10px  ;
}
.content_row1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
}
.content_row2{
    width: 90%;
    padding-left: 10px;
}

.home3_row2{
    display : flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
}
.suggested, .blue{
    font-weight: 600;
    font-size: 14px;
}
.blue{
    color: #2f80ed;
}
.col1_col1 > p{
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}
.col1_col2{
    padding-right: 20px;
}
.col1_date{
    font-size: 8px !important;
    opacity: 0.5 !important;
    font-weight: 600;
    text-align: center;
}
.content_row1{
    margin-top: 10px;
}