.filter_bg {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 110px;
  background-color: #fff;
  box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  padding: 5px 10px;
}
.filter_bg > p{
  font-size: 10px;
  font-weight: 500;
}
.filter_bg > img{
  cursor: pointer;
}