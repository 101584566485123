.input_bg {
  display: flex;
  width: 601.01px;
  height: 40 px;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 12px;
}

 .input_bg input {
  all: unset;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  padding: 0 25px;
}
