.button_name {
  all: unset;
  width: fit-content;
  padding: 2px 20px;
  background-color: #fff;
  border-radius: 4.2px;
  font-size: 10px;
  color: black;
  border: 0.437211px solid #000000;
  box-shadow: 0px 0.821648px 4.92989px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 0px 11.6589px rgba(0, 0, 0, 0.25));
  cursor: pointer;

}
.name {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}
