.wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 50px;
}

.heading{
    display: flex;
    flex-direction: column;
    

}
.search{
    display: flex;
    gap: 30px;
}
.row1_input {
    display: flex;
    width: 700.01px;
    height: 45px;
    align-items: center;
    padding: 10px;
    background-color: #f3f3f3;
    border-radius: 12px;
  }

.row1_input > img{
    width: 30px;
    height: 30px;
    padding-left: 10px;
}