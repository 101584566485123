.home1_bg {
  padding: 25px;
}

.row1 {
  display: flex;
  flex-direction: column;
  gap:4px;
}
.row1_heading {
  font-weight: 500;
  font-size: 20px;
}
.row1_inputs {
  display: flex;
  gap: 20px;
}

.row1_input {
  display: flex;
  width: 350.01px;
  height: 40 px;
  align-items: center;
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 12px;
}
.anywhere,
.location {
  width: 180px;
}

.filters {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}
