.navbar_bg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  width: 100vw;
  background-color: #22506c;
  filter: drop-shadow(7px 4px 6px rgba(0, 0, 0, 0.2));
  position: fixed;
  z-index: 1;
}
.title > img{
  width: 180px;
  padding: 22px;
  cursor: pointer;

}
.search_bar {
  display: flex;
  width: 601.01px;
  height: 40px;
  align-items: center;
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 12px;
}
.search_bar > input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  padding: 0 40px;
}
.search_bar > .glass {
  height: 23.24px;
  width: 22.73px;
  padding: 2px;
  cursor: pointer;
}
.individual {
  display: flex;
  align-items: center;
  padding: 10px;
}
.indi_img > img {
  width: 60px;
  height: 60px;
  filter: drop-shadow(0px 0.705255px 6.34729px #000000);
  margin-right: 10px;
  cursor: pointer;

}
.navbar_items {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 20px;
}
.navbar_item {
  height: 35px;
  width: 35px;
  cursor: pointer;

}
.indi_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
}
.bell{
    margin-top: 8px;
}